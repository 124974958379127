<template>
  <div class="RealNameAuditingRateList-wrapper">
    <list ref="list"
          exportMethod="delay"
          v-if="paramType===1"
          :exportPermission="exportPermission"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams1"
          :extraParams="extraParams"
          :headers="headers"
          :isMultiSelect="true">
      <template #searchSlot>
        <v-select2 label="所属项目"
                   v-model="searchParams1.communityId"
                   v-bind="communityParams"></v-select2>
        <v-datepicker label="选择年份"
                      type="year"
                      v-model="searchParams1.year"
                      :maxDate="maxDate"
                      :clearable="clearable"></v-datepicker>
      </template>
    </list>
    <list ref="list"
          exportMethod="delay"
          v-if="paramType===2"
          :exportPermission="exportPermission"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams2"
          :extraParams="extraParams"
          :headers="headers"
          :isMultiSelect="true">
      <template #searchSlot>
        <v-select2 label="所属项目"
                   v-model="searchParams2.communityId"
                   v-bind="communityParams"></v-select2>
        <v-datepicker label="选择年份"
                      type="year"
                      v-model="searchParams2.year"
                      :maxDate="maxDate"
                      :clearable="clearable"></v-datepicker>
        <v-select label="选择季度"
                  v-model="searchParams2.seasonNum"
                  :options="seasonNumOps"></v-select>
      </template>
    </list>
    <list ref="list"
          exportMethod="delay"
          v-if="paramType===3"
          :exportPermission="exportPermission"
          :searchUrl="searchUrl"
          :exportUrl="exportUrl"
          :searchParams.sync="searchParams3"
          :extraParams="extraParams"
          :headers="headers"
          :isMultiSelect="true">
      <template #searchSlot>
        <v-select2 label="所属项目"
                   v-model="searchParams3.communityId"
                   v-bind="communityParams"></v-select2>
        <v-datepicker label="选择月份"
                      type="month"
                      v-model="searchParams3.yearMonth"
                      :maxDate="maxDate"
                      :clearable="clearable"></v-datepicker>
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { getListURL, exportListURL } from './api'

export default {
  name: 'RealNameAuditingRateList',
  data () {
    return {
      clearable: false,
      exportPermission: true,
      maxDate: new Date(),
      searchUrl: getListURL,
      exportUrl: exportListURL,
      communityParams,
      paramType: undefined,
      extraParams: {},
      searchParams1: {
        communityId: undefined,
        paramType: undefined,
        year: new Date().getFullYear()
      },
      searchParams2: {
        communityId: undefined,
        paramType: undefined,
        seasonNum: 1,
        year: new Date().getFullYear()
      },
      searchParams3: {
        communityId: undefined,
        paramType: undefined,
        yearMonth: (new Date().getFullYear()) + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1))
      },
      headers: [
        {
          prop: 'regionName',
          label: '所属分公司'
        },
        {
          prop: 'communityName',
          label: '所属园区'
        },
        {
          prop: 'userName',
          label: '用户姓名'
        },
        {
          prop: 'mobileNum',
          label: '手机号'
        },
        {
          prop: 'intime',
          label: '申请时间'
        },
        {
          prop: 'approveTime',
          label: '审核时间'
        },
        {
          prop: 'status',
          label: '审核状态'
        }
      ]
    }
  },
  created () {
    let queryData = this.$route.query
    this.initSearchParams()
    queryData.year && (this.extraParams.year = queryData.year)
    queryData.paramType && (this.extraParams.paramType = queryData.paramType)
    queryData.paramType && (this.paramType = queryData.paramType)
    queryData.communityId && (this.extraParams.communityId = queryData.communityId)
    queryData.seasonNum && (this.extraParams.seasonNum = queryData.seasonNum)
    queryData.yearMonth && (this.extraParams.yearMonth = queryData.yearMonth)
  },
  methods: {
    initSearchParams () {
      this.searchParams1 = {
        communityId: undefined,
        paramType: undefined,
        year: new Date().getFullYear()
      }
      this.searchParams2 = {
        communityId: undefined,
        paramType: undefined,
        seasonNum: 1,
        year: new Date().getFullYear()
      }
      this.searchParams3 = {
        communityId: undefined,
        paramType: undefined,
        yearMonth: (new Date().getFullYear()) + '-' + ((new Date().getMonth() + 1) > 9 ? (new Date().getMonth() + 1) : '0' + (new Date().getMonth() + 1))
      }
    }
  }
}
</script>
